// On document ready
jQuery(document).ready(function ($) {
    $('.js-toggle-ndd-menu').on('click', function () {
        $('body').toggleClass('ndd-menu-open');
    });

    if ($('.js-cross-archive--item').length) {
        blockCrossArchive();
    }

    function blockCrossArchive() {
        const $loadMore = $('.cross-archive__load-more');
        loadMore();

        function loadMore() {
            $loadMore.on('click', function () {
                getPosts(true);
            });
        }

        function getCurrentPostIds() {
            const ids = [];
            $('.js-cross-archive--item').each(function () {
                ids.push($(this).data('id'));
            });

            return ids;
        }

        function getPosts(loadMore = false) {
            $.ajax({
                type: 'POST',
                url: menaObject.ajaxurl,
                dataType: 'html',
                data: {
                    action: 'get_cross_archive_posts',
                    current_ids: loadMore ? getCurrentPostIds() : [],
                },

                beforeSend: function () {
                    $loadMore.addClass('loading');
                },

                success: function (response) {
                    response = JSON.parse(response);

                    if (response.success) {
                        if (loadMore) {
                            $('.cross-archive__container').append(response.data.posts);
                        }

                        if (!response.data.loadMore) {
                            $loadMore.remove();
                        }
                    } else {
                        console.error(response);
                    }

                    $loadMore.removeClass('loading');
                },

                error: function (response) {
                    console.error(response);
                },
            });
        }
    }
});
